// Import necessary libraries
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getChatID } from './chatIDModule';
const TextToSpeechButton = ({ textToSpeak, isLastMessage, audioOn, selectLanguage }) => {
	
	const [audioFile, setAudioFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [audioVisible, setAudioVisible] = useState(false);
const [chunks, setChunks] = useState('');
const [buttonClicked, setButtonClicked] = useState(false);

	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const togglePlay = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const stopAudio = () => {
		audioRef.current.pause();
		audioRef.current.currentTime = 0;
		setIsPlaying(false);
	};

	const handleTextToSpeech = async () => {
// Check if the button has already been clicked
		if (buttonClicked) {
			return;
		}
		try {
			if (audioVisible) {
				setAudioVisible(false);
				setAudioFile(null);
setChunks('');

				return;
			}
			// Set loading state to true
setButtonClicked(true);
			setLoading(true);

			// Make a POST request to the text-to-speech API endpoint
			console.log('lang for TTS:', selectLanguage);
			console.log('text for TTS:', chunks);
			let chatID = Cookies.get('ChatID');
			if (!chatID) {
				chatID = getChatID();
				if (!chatID) {
					console.error('ChatID non trouvé');
					return;
			}
		
		}
			const config = {
				headers: {
					'Authorization': `Bearer ${chatID}`,
				},
				// withCredentials: true,
				responseType: 'arraybuffer' // Assurer que la réponse est traitée comme un flux binaire
			};
			const response = await axios.post(
				'https://demo-cbmr-2.azurewebsites.net/api/TTSapi?code=KjPAAzKWHM00bDWtOms46rl80Jh2LHLurGLkWqEUrPs4AzFuTA_WXA==',
				{ Ai_message: chunks, auth_key : "Ecp)KyWhR}%kdA5$-C-InQopRp$MTQ8DINtPDY^kQQQZ&[2Q5U", lang: selectLanguage  },
				config
			);

			const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
			const audioUrl = URL.createObjectURL(audioBlob);
			setAudioVisible(true);
			setAudioFile(audioUrl);
					} catch (error) {
			if (error.response) {
				// La requête a été effectuée et le serveur a répondu avec un code d'erreur
				console.error("Réponse du serveur avec code d'erreur:", error.response);
			} else if (error.request) {
				// La requête a été effectuée, mais aucune réponse n'a été reçue
				console.error('Aucune réponse du serveur reçue:', error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la requête
				console.error(
					'Erreur lors de la configuration de la requête:',
					error.message
				);
			}
		} finally {
			// Set loading state back to false
			setLoading(false);
		}
	};

	useEffect(() => {
		if (audioOn && chunks !== '') {
			handleTextToSpeech();
		}
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audioOn, chunks]);

useEffect(() => {
		setChunks(textToSpeak);
	}, [textToSpeak]);
	return (
		<div
			className={`ml-1 flex w-1/5 ${
				audioVisible ? 'bg-[#016074] rounded-full' : ''
			} transition-transform`}>
			<button
				className={`bg-[#016074] text-white px-1 py-1 rounded-full ml-2 inline ${
					isLastMessage ? '' : 'hidden'
				}`}
				onClick={handleTextToSpeech}
				disabled={loading}>
				<svg
					width='19'
					height='19'
					viewBox='0 0 18 19'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					className=''>
					<path
						d='M8.25 3.95825L4.5 7.12492H1.5V11.8749H4.5L8.25 15.0416V3.95825Z'
						stroke='white'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M14.3025 3.90283C15.7086 5.38743 16.4984 7.4007 16.4984 9.49992C16.4984 11.5991 15.7086 13.6124 14.3025 15.097M11.655 6.69742C12.358 7.43971 12.753 8.44635 12.753 9.49596C12.753 10.5456 12.358 11.5522 11.655 12.2945'
						stroke='white'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</button>

			{audioFile && audioVisible && (
<div className='custom-audio'>
				<audio
					ref={audioRef}
					src={audioFile}
					autoPlay={audioOn}></audio>
					<button
						onClick={togglePlay}
						className='ml-2 mr-1'>
						{isPlaying ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='18'
								viewBox='0 0 15.75 24.75'>
								<path
									id='Icon_ion-pause'
									data-name='Icon ion-pause'
									d='M14.625,30.375H11.25a1.125,1.125,0,0,1-1.125-1.125V6.75A1.125,1.125,0,0,1,11.25,5.625h3.375A1.125,1.125,0,0,1,15.75,6.75v22.5A1.125,1.125,0,0,1,14.625,30.375Zm10.125,0H21.375A1.125,1.125,0,0,1,20.25,29.25V6.75a1.125,1.125,0,0,1,1.125-1.125H24.75A1.125,1.125,0,0,1,25.875,6.75v22.5A1.125,1.125,0,0,1,24.75,30.375Z'
									transform='translate(-10.125 -5.625)'
									fill='#fff'
								/>
							</svg>
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='18'
								viewBox='0 0 22.497 25.875'>
								<path
									id='Icon_ion-play'
									data-name='Icon ion-play'
									d='M9.352,30.938a2.487,2.487,0,0,1-1.23-.328A2.765,2.765,0,0,1,6.753,28.2V7.8A2.762,2.762,0,0,1,8.121,5.391a2.47,2.47,0,0,1,2.515.032L28.063,15.854a2.531,2.531,0,0,1,0,4.289L10.633,30.577A2.5,2.5,0,0,1,9.352,30.938Z'
									transform='translate(-6.753 -5.062)'
									fill='#fff'
								/>
							</svg>
						)}
					</button>

					<img
						src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/wave2.gif'
						alt=''
						className=' h-8 w-20 object-fill '
					/>

					<button
						onClick={stopAudio}
						className='ml-2 mr-1'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='12'
							height='17'
							viewBox='0 0 24.75 24.75'>
							<path
								id='Icon_ion-stop'
								data-name='Icon ion-stop'
								d='M27.563,30.375H8.438a2.813,2.813,0,0,1-2.812-2.812V8.438A2.813,2.813,0,0,1,8.438,5.625H27.563a2.813,2.813,0,0,1,2.813,2.813V27.563A2.813,2.813,0,0,1,27.563,30.375Z'
								transform='translate(-5.625 -5.625)'
								fill='#fff'
							/>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
};

export default TextToSpeechButton;
