// Welcome.js

import React from 'react';

const Welcome = () => {
    return (
        <div className='flex flex-col items-center justify-center h-full'>
            {/* Nouvelle image ajoutée ici */}
            {/* <img
                src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/Programme_2024.png'
                alt='Programme 2024'
                className='w-22 mb-5' // Ajoutez des classes pour ajuster la taille et la marge selon besoin
            /> */}
            <img
                src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/VilledeHannut_droit_Quadri.png'
                alt='Welcome'
                className='w-26.25 h-16 mb-5'
            />
            <div className='text-left'>
                <h1 className='text-xl font-bold mb-2 ml-5 mr-5'>
                    Bonjour, je suis l'assistant IA de la ville de Hannut !
                </h1>
                <p className='text-l ml-5 mr-5'>
                    Posez-moi toutes vos questions.
                </p>
                <p className='text-m ml-5 mr-5'>Hannut est à vous</p>
            </div>
        </div>
    );
};

export default Welcome;
// Welcome.js
